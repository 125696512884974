import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Navbar() {
  const [isNavbarVisible, setIsNavbarVisible] = useState(false);

  const toggleNavbar = () => {
    setIsNavbarVisible(!isNavbarVisible);
  };

  return (
    <div className="home-index">
      <div className="navbar-toggle">
        <Link to="/Explore">
          <div className="gicon-title">
            <span class="material-symbols-outlined">explore</span>
            <p>Explore</p>
          </div>
        </Link>
        <button className="navbar-btn" onClick={toggleNavbar}>
          <span class="material-symbols-outlined">menu</span>
        </button>
      </div>

      <div className={`promo ${isNavbarVisible ? "show" : ""}`}>
        <div className="index-promo">
          <Link to="/create">
            <div className="gicon-title">
              <span class="material-symbols-outlined">post_add</span>
              <p>New Post</p>
            </div>
          </Link>

          <Link to="/contact">
            <div className="gicon-title">
              <span class="material-symbols-outlined">mail</span>
              <p>Contact</p>
            </div>
          </Link>
        </div>

        <div className="index-promo">
          <Link to="/PlagGuard">
            <div className="gicon-title">
              <span class="material-symbols-outlined">all_inclusive</span>
              <p>PlagGuard AI</p>
            </div>
          </Link>

          <Link to="/safecontent">
            <div className="gicon-title">
              <span class="material-symbols-outlined">spellcheck</span>
              <p>SafeContent AI</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
