import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import Editor from "../components/Editor";
import { Helmet } from "react-helmet";
import AlertComponent from "../components/alertBox";

export default function EditPost() {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");
  const [PostType, setPostType] = useState("");
  const [files, setFiles] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_URL}/post/`;
    fetch(url + id).then((response) => {
      response.json().then((postInfo) => {
        setTitle(postInfo.title);
        setContent(postInfo.content);
        setSummary(postInfo.summary);
        setPostType(postInfo.PostType);
      });
    });
  }, [id]);

  async function updatePost(ev) {
    ev.preventDefault();
    const data = new FormData();
    data.set("title", title);
    data.set("summary", summary);
    data.set("content", content);
    data.set("PostType", PostType);
    data.set("id", id);
    if (files?.[0]) {
      data.set("file", files?.[0]);
    }
    const response = await fetch(`${process.env.REACT_APP_API_URL}/update`, {
      method: "PUT",
      body: data,
      credentials: "include",
    });

    if (response.ok) {
      localStorage.removeItem(`post-${id}`);
      setSuccessMessage("Post updated!");
      setRedirect(true);
    } else {
      setErrorMessage("Failed to update post");
    }
  }

  if (redirect) {
    return <Navigate to={"/post/" + id} />;
  }

  return (
    <>
      <Helmet>
        <title>Update: {title}</title>
      </Helmet>
      <AlertComponent status="success" message={successMessage} />
      <AlertComponent status="error" message={errorMessage} />

      <form className="report" onSubmit={updatePost}>
        <h1>Update Content</h1>
        <p>Header</p>
        <input
          type="title"
          placeholder={"Title"}
          value={title}
          onChange={(ev) => setTitle(ev.target.value)}
        />
        <p>Summary</p>
        <input
          type="summary"
          placeholder={"Summary"}
          value={summary}
          onChange={(ev) => setSummary(ev.target.value)}
        />
        <p>Post Catagory</p>
        <select
          type="PostType"
          value={PostType}
          onChange={(ev) => setPostType(ev.target.value)}
        >
          <optgroup>
            <option disabled value="">
              Category
            </option>
            <option>Business</option>
            <option>News</option>
            <option>Science and Technology</option>
            <option>Entertainment</option>
            <option>Gaming</option>
            <option>Sports</option>
            <option>Health</option>
            <option>Lifestyle and Travel</option>
            <option>Food</option>
            <option>Opinions</option>
          </optgroup>
        </select>
        <p>Thumbnail</p>
        <input type="file" onChange={(ev) => setFiles(ev.target.files)} />
        <Editor onChange={setContent} value={content} />
        <button style={{ marginTop: "10px" }}>Update post</button>
      </form>
    </>
  );
}
