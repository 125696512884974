import { useState, useContext } from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { UserContext } from "../components/userContext";
import { Helmet } from "react-helmet";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AlertComponent from "../components/alertBox";

export default function LoginPage() {
  const navigate = useNavigate();
  const navigateToRegister = () => navigate("/register");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const { setUserInfo } = useContext(UserContext);

  async function login(ev) {
    ev.preventDefault();
    const url = `${process.env.REACT_APP_API_URL}/login`;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });

    if (!response.ok) {
      const errorData = await response.json();
      if (errorData.error === "401") {
        setErrorMessage("Account not found");
      } else if (errorData.error === "402") {
        setErrorMessage("Wrong password");
      } else {
        setErrorMessage("Server error, sorry it's our fault");
      }
    } else {
      const userInfo = await response.json();
      if (rememberMe) {
        localStorage.setItem("token", userInfo.token);
      } else {
        localStorage.removeItem("token");
      }
      setUserInfo(userInfo);
      setRedirect(true);
    }
  }

  if (redirect) {
    return <Navigate to={"/"} />;
  }

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <AlertComponent status="error" message={errorMessage} />
      <form className="login" onSubmit={login}>
        <h1>Welcome Back!</h1>
        <div className="gicon-title-main">
          <span class="material-symbols-outlined">account_circle</span>
          <p>Username or Email</p>
        </div>
        <input
          type="text"
          placeholder="name@company.com"
          value={username}
          required
          autoComplete="username"
          onChange={(ev) => setUsername(ev.target.value)}
        />
        <div className="gicon-title-main">
          <span class="material-symbols-outlined">lock</span>
          <p>Password</p>
        </div>
        <input
          type="password"
          placeholder="••••••••"
          required
          value={password}
          onChange={(ev) => setPassword(ev.target.value)}
        />
        <Link to="/ResetPassword">Forgot password?</Link>

        <button type="submit">Login</button>
        <FormControlLabel
          isInvalid
          onChange={(e) => setRememberMe(e.target.checked)}
          isChecked={rememberMe}
          control={
            <Checkbox
              defaultChecked
              sx={{ fontSize: "small", color: "white" }}
            />
          }
          label="Remember me"
        />
        <h4>New to Blogstera?</h4>
        <button type="button" onClick={navigateToRegister}>
          Create account
        </button>
      </form>
    </>
  );
}
