import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "./userContext";

const ProtectedRoute = ({ children }) => {
  const { userInfo } = useContext(UserContext);

  if (!userInfo || !userInfo.username) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
