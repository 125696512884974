import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import AlertComponent from "../components/alertBox";

export default function ReportPost() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [query, setQuery] = useState("");
  const [reportType, setReportType] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const location = useLocation();
  const { author, postName } = location.state || {};

  const handleReportTypeChange = (value) => {
    if (value && value !== "") {
      document.getElementById("reportType").classList.remove("select-error");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!reportType || reportType === "") {
      document.getElementById("reportType").classList.add("select-error");
      setErrorMessage("Please select a reason for reporting.");
      return;
    }

    try {
      const url = `${process.env.REACT_APP_API_URL}/report`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          query,
          reportType,
          author,
          postName,
        }),
      });

      if (response.ok) {
        setSuccessMessage("Thank you for Reporting");
      } else {
        setErrorMessage("Please fill out all the fields.");
      }
    } catch (error) {
      setErrorMessage("Error submitting form: " + error.message);
    }
  };

  return (
    <>
      <>
        <Helmet>
          <title>Report: {postName}</title>
        </Helmet>
        <AlertComponent status="success" message={successMessage} />
        <AlertComponent status="error" message={errorMessage} />
        <form className="report" onSubmit={handleSubmit}>
          <h1>Report Content</h1>
          <p>Author Name</p>
          <input
            style={{ cursor: "not-allowed" }}
            className="disabled-input"
            type="text"
            value={author}
            disabled
          />
          <p>Post Title</p>
          <input
            style={{ cursor: "not-allowed" }}
            className="disabled-input"
            type="text"
            value={postName}
            disabled
          />
          <p>Your name</p>
          <input
            type="text"
            placeholder="Enter name"
            value={name}
            onChange={(ev) => setName(ev.target.value)}
            required
          />
          <p>Email</p>
          <input
            type="email"
            placeholder="name@company.com"
            value={email}
            onChange={(ev) => setEmail(ev.target.value)}
            required
          />
          <p>Reason</p>
          <select
            id="reportType"
            value={reportType}
            onChange={(ev) => {
              setReportType(ev.target.value);
              handleReportTypeChange(ev.target.value);
            }}
            required
          >
            <optgroup>
              <option disabled value="">
                Select
              </option>
              <option>It's spam</option>
              <option>Nudity or sexual content</option>
              <option>Hate speech</option>
              <option>Violence content</option>
              <option>Dangerous content</option>
              <option>Sale of illegal goods</option>
              <option>Intellectual property</option>
              <option>Drugs</option>
              <option>False information</option>
              <option>Scam or fraud</option>
            </optgroup>
          </select>
          <p>Further Mentions</p>
          <textarea
            type="text"
            placeholder="Enter message"
            value={query}
            onChange={(ev) => setQuery(ev.target.value)}
          />
          <button type="submit">Submit</button>
          <div className="report-div">
            <h3>Reporting Guidelines</h3>
            <p>
              If you believe content violates our guidelines, please report it.
              We’ll review each report carefully. Your help ensures a safe,
              respectful environment for all
            </p>
          </div>
        </form>
      </>
    </>
  );
}
