import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "react-quill/dist/quill.snow.css";
import Editor from "../components/Editor";
import AlertComponent from "../components/alertBox";

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "code-block",
];

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["link", "image"],
    ["clean"],
    ["bold", "italic", "underline", "strike"],
    ["code-block"],
  ],
  syntax: true,
};

export default function CreatePost() {
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");
  const [PostType, setPostType] = useState("");
  const [files, setFiles] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const updateSummary = (ev) => {
    const inputSummary = ev.target.value;
    const limitedSummary = inputSummary.split(" ").slice(0, 30).join(" ");
    setSummary(limitedSummary);
  };

  const handleKeyDown = (event) => {
    const maxWords = 5;
    const words = event.target.value.split(" ");
    if (
      words.length >= maxWords &&
      event.key !== "Backspace" &&
      event.key !== "Delete"
    ) {
      event.preventDefault();
    }
  };

  const createNewPost = async (ev) => {
    const data = new FormData();
    data.set("title", title);
    data.set("summary", summary);
    data.set("content", content);
    data.set("PostType", PostType);
    data.set("file", files[0]);

    ev.preventDefault();
    console.log(files);
    const url = `${process.env.REACT_APP_API_URL}/post`;
    const response = await fetch(url, {
      method: "POST",
      body: data,
      credentials: "include",
    });

    if (response.ok) {
      setRedirect(true);
      setSuccessMessage("Post created");
    } else {
      setErrorMessage("Failed to create post");
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (redirect) {
        <Navigate to="/" replace />;
        window.location.href = "/";
        window.scrollTo(0, 0);
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [redirect]);

  return (
    <>
      <Helmet>
        <title>Create Post</title>
      </Helmet>
      <AlertComponent status="success" message={successMessage} />
      <AlertComponent status="error" message={errorMessage} />

      <form className="report" onSubmit={createNewPost}>
        <h1>Create Content</h1>
        <p>Header</p>
        <input
          type="title"
          placeholder={"Title"}
          required
          value={title}
          onChange={(ev) => setTitle(ev.target.value)}
        />
        <p>Summary</p>
        <input
          type="summary"
          placeholder={"Brief"}
          required
          value={summary}
          onChange={updateSummary}
          onKeyDown={handleKeyDown}
        />
        <p>Post Catagory</p>
        <select
          id="PostType"
          value={PostType}
          onChange={(ev) => {
            setPostType(ev.target.value);
          }}
          required
        >
          <optgroup>
            <option disabled value="">
              Select
            </option>
            <option>Business</option>
            <option>News</option>
            <option>Science</option>
            <option>Technology</option>
            <option>Entertainment</option>
            <option>Gaming</option>
            <option>Sports</option>
            <option>Health</option>
            <option>Lifestyle</option>
            <option>Travel</option>
            <option>Food</option>
            <option>Opinions</option>
          </optgroup>
        </select>
        <p>Thumbnail</p>
        <input
          required
          type="file"
          accept="image/*"
          onChange={(ev) => {
            const file = ev.target.files[0];
            const maxSizeInMB = 2;
            const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
            const maxWidth = 1920;
            const maxHeight = 1080;

            if (!file) return;

            if (!file.type.startsWith("image/")) {
              setErrorMessage("Only image files are supported");
              window.scrollTo(0, 0);
              ev.target.value = "";
              return;
            }

            if (file.size > maxSizeInBytes) {
              setErrorMessage(
                `File size should be less than ${maxSizeInMB} MB`
              );
              window.scrollTo(0, 0);
              ev.target.value = "";
              return;
            }

            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = () => {
              if (img.width > maxWidth || img.height > maxHeight) {
                setErrorMessage(
                  `Image dimensions should be within ${maxWidth}x${maxHeight}px`
                );
                window.scrollTo(0, 0);
                ev.target.value = "";
              } else {
                setFiles(ev.target.files);
                setSuccessMessage("Image Accepted");
                window.scrollTo(0, 0);
              }
            };
          }}
        />

        <Editor
          value={content}
          theme="snow"
          onChange={(newValue) => setContent(newValue)}
          modules={modules}
          formats={formats}
        />

        <button style={{ marginTop: "10px" }}>Post</button>
        <div className="contact-div">
          <p>
            No explicit or offensive material, support for illegal activities,
            or piracy links. Respect user privacy, engage in civil
            communication, and report violations for a positive community.
            Violations may lead to content removal or account actions.
          </p>
        </div>
      </form>
    </>
  );
}
