import { useState, useContext } from "react";
import { Navigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { UserContext } from "../components/userContext";
import AlertComponent from "../components/alertBox";

export default function RegisterPage() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [interestType, setInterestType] = useState("");
  const [password, setPassword] = useState("");
  const { setUserInfo } = useContext(UserContext);
  const [redirect, setRedirect] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleLogin = async (username, password) => {
    const url = `${process.env.REACT_APP_API_URL}/login`;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });

    if (response.ok) {
      const userInfo = await response.json();
      document.cookie = `token=${userInfo.token}; path=/`;
      setUserInfo(userInfo);
      setRedirect(true);
    } else {
      const errorMessage = await response.text();
      console.log(errorMessage);
    }
  };

  if (redirect) {
    return <Navigate to={"/"} />;
  }

  async function register(ev) {
    ev.preventDefault();
    const url = `${process.env.REACT_APP_API_URL}/register`;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({ username, password, email, interestType }),
      headers: { "Content-Type": "application/json" },
    });

    if (!response.ok) {
      const errorData = await response.json();
      if (errorData.error === "401") {
        setErrorMessage("Password must be at least 4 characters long");
      } else if (errorData.error === "402") {
        setErrorMessage("Username already exists");
      } else if (errorData.error === "403") {
        setErrorMessage("Email already exists");
      } else {
        setErrorMessage("Failed to create account");
      }
    } else {
      setSuccessMessage("Account created!");
      await handleLogin(username, password);
    }
  }

  return (
    <>
      <Helmet>
        <title>Create an account</title>
        <meta
          name="description"
          content="Sign up to blogstera today and unlock exclusive benefits! Sign up now to access premium content, receive updates, and connect with like-minded individuals. Start your journey with us and discover new opportunities to learn, grow, and thrive."
        />
      </Helmet>
      <AlertComponent status="success" message={successMessage} />
      <AlertComponent status="error" message={errorMessage} />

      <form class="login" onSubmit={register}>
        <h1>Create account</h1>
        <div className="gicon-title-main">
          <span class="material-symbols-outlined">account_circle</span>
          <p>Username</p>
        </div>
        <input
          type="text"
          placeholder="Username"
          value={username}
          required
          onChange={(ev) => setUsername(ev.target.value.replace(/\s/g, ""))}
        />
        <div className="gicon-title-main">
          <span class="material-symbols-outlined">mail</span>
          <p>Email</p>
        </div>
        <input
          type="email"
          placeholder="name@company.com"
          value={email}
          required
          onChange={(ev) => setEmail(ev.target.value)}
        />
        <div className="gicon-title-main">
          <span class="material-symbols-outlined">topic</span>
          <p>Topic</p>
        </div>
        <select
          id="PostType"
          value={interestType}
          onChange={(ev) => {
            setInterestType(ev.target.value);
          }}
          required
        >
          <optgroup>
            <option disabled value="">
              Select your favourite topic
            </option>
            <option>Business</option>
            <option>News</option>
            <option>Science</option>
            <option>Technology</option>
            <option>Entertainment</option>
            <option>Sports</option>
            <option>Health</option>
            <option>Lifestyle</option>
            <option>Travel</option>
            <option>Food</option>
            <option>Opinions</option>
          </optgroup>
        </select>
        <div className="gicon-title-main">
          <span class="material-symbols-outlined">lock</span>
          <p>Password</p>
        </div>
        <input
          type="password"
          placeholder="••••••••"
          value={password}
          required
          onChange={(ev) => setPassword(ev.target.value)}
        />
        <Link to="/contact">Cant't Sign up?</Link>
        <button>Register</button>
      </form>
    </>
  );
}
