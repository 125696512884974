import Footer from "../components/footer";
import { Helmet } from "react-helmet";

export default function About() {
  return (
    <>
      <Helmet>
        <title>About blogstera and it's Policies</title>
      </Helmet>
      <div className="about">
        <h3>About Blogstera</h3>
        <p>
          At Blogstera, we believe that everyone has a story to tell, a
          perspective to share, and a voice that deserves to be heard. Our
          platform is dedicated to empowering individuals to express themselves
          freely and connect with like-minded people from around the world.
        </p>

        <p>
          Our mission is to create a dynamic and inclusive community where
          bloggers, writers, and content creators can share their passions,
          insights, and experiences. We strive to provide a space that fosters
          creativity, encourages open dialogue, and celebrates diverse voices.
        </p>

        <h3>Privacy Policy for Blogstera</h3>
        <p>
          Welcome to Blogstera. This Privacy Policy outlines how we collect,
          use, disclose, and safeguard your information when you visit our
          website blogstera.site. Please read this privacy policy carefully. If
          you do not agree with the terms of this privacy policy please do not
          access the Site.
        </p>

        <p>
          We may collect information about you in a variety of ways. The
          information we may collect on the Site includes personal data.
          Personally identifiable information, such as your name, email address,
          image and interests that you voluntarily give to us when you register
          with the Site or when you choose to participate in various activities
          related to the Site such as uploading articles.
        </p>

        <h3>Disclosure of Your Information</h3>
        <p>
          We do not share, sell, rent, or trade your information with third
          parties for their promotional purposes. We use administrative and
          technical security measures to help protect your personal information.
          While we have taken reasonable steps to secure the personal
          information you provide to us. So please be aware that despite our
          efforts no security measures are perfect or impenetrable and no method
          of data transmission can be guaranteed against any interception or
          other type of misuse.
        </p>

        <h3>Reporting and Community Guidelines</h3>
        <p>
          If you believe that content on our platform violates our community
          guidelines, please report it to us. We take reports seriously and will
          review them carefully. However, we kindly ask that you only report
          content that you genuinely believe violates our guidelines.Your
          cooperation helps us maintain a safe and respectful environment for
          everyone.
        </p>

        <p>
          Create respectful content - no explicit or offensive material, support
          for illegal activities, or piracy links. Respect user privacy, engage
          in civil communication, and report violations for a positive
          community. Violations may lead to content removal or account actions.
        </p>

        <p>
          When a report matches our guidelines and we find that a user has
          violated our community guidelines, we will take appropriate action.
          Content violating guidelines will be removed also repeat offenders may
          face account restrictions or bans.We strive to maintain a safe and
          respectful community and appreciate your cooperation in upholding
          these standards.
        </p>

        <h3>We may change Policies</h3>
        <p>
          We may update this Privacy Policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal, or regulatory reasons.
        </p>
      </div>
      <Footer />
    </>
  );
}
