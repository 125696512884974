import { useState } from "react";
import AlertComponent from "./alertBox";

export default function Feedback() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [query, setQuery] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = `${process.env.REACT_APP_API_URL}/contact`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, query }),
      });

      if (response.ok) {
        setSuccessMessage("Thanks for your feedback!");
      } else {
        setErrorMessage("Please fill out all the fields.");
      }
    } catch (error) {
      setErrorMessage("An error occurred while submitting the form.");
    }
  };

  return (
    <>
      <AlertComponent status="success" message={successMessage} />
      <AlertComponent status="error" message={errorMessage} />
      <form className="feedback" onSubmit={handleSubmit}>
        <h4>Feedback</h4>
        <hr></hr>
        <div className="gicon-title-main">
          <span class="material-symbols-outlined">account_circle</span>
          <p>Name</p>
        </div>
        <input
          type="text"
          placeholder="Enter name"
          required
          value={name}
          onChange={(ev) => setName(ev.target.value)}
        />
        <div className="gicon-title-main">
          <span class="material-symbols-outlined">mail</span>
          <p>Email</p>
        </div>
        <input
          type="email"
          required
          placeholder="name@company.com"
          value={email}
          onChange={(ev) => setEmail(ev.target.value)}
        />
        <div className="gicon-title-main">
          <span class="material-symbols-outlined">comment</span>
          <p>Message</p>
        </div>
        <textarea
          type="text"
          value={query}
          placeholder="Enter Your Feedback about our page"
          onChange={(ev) => setQuery(ev.target.value)}
        />
        <button>Send Feedback</button>
      </form>
    </>
  );
}
