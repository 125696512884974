import React, { useEffect } from "react";
import ReactQuill from "react-quill";
import "../App.css";
import hljs from "highlight.js";
import "highlight.js/styles/github.css";

const modules = {
  syntax: {
    highlight: (text) => hljs.highlightAuto(text).value,
  },
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { script: "sub" },
      { script: "super" },
    ],
    ["link", "image", "video", "code-block"],
    ["clean"],
  ],
};

export default function Editor({ value, onChange }) {
  useEffect(() => {
    hljs.configure({ languages: ["javascript", "bash"] });
  }, []);

  return (
    <div className="quill-wrapper">
      <ReactQuill
        value={value}
        theme="snow"
        onChange={onChange}
        modules={modules}
        scrollingContainer=".quill-wrapper"
      />
    </div>
  );
}
