import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "./userContext";
import Navbar from "./Navbar";
import AlertComponent from "./alertBox";

export default function Header() {
  const { setUserInfo, userInfo } = useContext(UserContext);
  const [userData, setUserData] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_URL}/profile`;
    const token = userInfo?.token;
    fetch(url, {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Failed to fetch profile: ${response.statusText}`);
        }
        return response.json();
      })
      .then((userInfo) => {
        setUserInfo(userInfo);
      })
      .catch((error) => {
        console.error("Error fetching profile:", error.message);
      });
  }, [setUserInfo, userInfo?.token]);

  useEffect(() => {
    if (userInfo?.username) {
      const fetchUserData = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/users/${userInfo.username}`
          );
          if (response.ok) {
            const data = await response.json();
            setUserData(data);
          } else {
            console.error("Failed to fetch user data");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

      fetchUserData();
    }
  }, [userInfo]);

  async function logout() {
    const url = `${process.env.REACT_APP_API_URL}/logout`;
    const response = await fetch(url, {
      credentials: "include",
      method: "POST",
    });

    if (response.ok) {
      setSuccessMessage("Log out successful");
      navigate("/");
      setUserInfo(null);
    }
  }

  const username = userInfo?.username;

  return (
    <div className="header-container">
      <header>
        <div className="logo">
          <Link to="/">
            <img className="icon" src="/b.png" alt="Blog Logo" />
          </Link>
          <Link className="web-title" to="/">
            Blogstera
          </Link>
        </div>
        <nav>
          {username ? (
            <div className="index-promo">
              <Link to={`/user/${username}`}>
                <div className="gicon-title">
                  {userData?.icon ? (
                    <img
                      className="promo-icon"
                      src={userData.icon}
                      alt="User Icon"
                      style={{
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                        marginRight: "3px",
                      }}
                    />
                  ) : null}
                  <p>{username}</p>
                </div>
              </Link>

              <Link className="logout" to="/Explore">
                <div className="gicon-title">
                  <span class="material-symbols-outlined">explore</span>
                  <p>Explore</p>
                </div>
              </Link>

              <Link className="logout" to="/create">
                <div className="gicon-title">
                  <span class="material-symbols-outlined">post_add</span>
                  <p>New Post</p>
                </div>
              </Link>

              <Link className="logout" to="/PlagGuard">
                <div className="gicon-title">
                  <span class="material-symbols-outlined">all_inclusive</span>
                  <p>PlagGuard AI</p>
                </div>
              </Link>

              <Link className="logout" to="/safecontent">
                <div className="gicon-title">
                  <span class="material-symbols-outlined">spellcheck</span>
                  <p>SafeContent AI</p>
                </div>
              </Link>

              <Link className="logout" to="/about">
                <div className="gicon-title">
                  <span class="material-symbols-outlined">policy</span>
                  <p>About & Legal</p>
                </div>
              </Link>

              <Link className="logout" to="/contact">
                <div className="gicon-title">
                  <span class="material-symbols-outlined">mail</span>
                  <p>Contact</p>
                </div>
              </Link>

              <Link onClick={logout}>
                <div className="gicon-title">
                  <span class="material-symbols-outlined">logout</span>
                  <p className="logout">Log out</p>
                </div>
              </Link>
            </div>
          ) : (
            <>
              <div className="index-promo">
                <Link className="logout" to="/Explore">
                  <div className="gicon-title">
                    <span class="material-symbols-outlined">explore</span>
                    <p>Explore</p>
                  </div>
                </Link>

                <Link className="logout" to="/create">
                  <div className="gicon-title">
                    <span class="material-symbols-outlined">post_add</span>
                    <p>New Post</p>
                  </div>
                </Link>

                <Link className="logout" to="/PlagGuard">
                  <div className="gicon-title">
                    <span class="material-symbols-outlined">all_inclusive</span>
                    <p>PlagGuard AI</p>
                  </div>
                </Link>

                <Link className="logout" to="/safecontent">
                  <div className="gicon-title">
                    <span class="material-symbols-outlined">spellcheck</span>
                    <p>SafeContent AI</p>
                  </div>
                </Link>

                <Link className="logout" to="/about">
                  <div className="gicon-title">
                    <span class="material-symbols-outlined">policy</span>
                    <p>About & Legal</p>
                  </div>
                </Link>

                <Link className="logout" to="/contact">
                  <div className="gicon-title">
                    <span class="material-symbols-outlined">mail</span>
                    <p>Contact</p>
                  </div>
                </Link>

                <Link to="/login">
                  <div className="gicon-title">
                    <span class="material-symbols-outlined">login</span>
                    <p>Sign in</p>
                  </div>
                </Link>
              </div>
            </>
          )}
        </nav>
      </header>
      <div className="navbar">
        <Navbar />
      </div>
      <AlertComponent status="success" message={successMessage} />
    </div>
  );
}
