import { useState } from "react";
import AlertComponent from "../components/alertBox";
import { Helmet } from "react-helmet";

export default function ContactPage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [query, setQuery] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = `${process.env.REACT_APP_API_URL}/contact`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, query }),
      });

      if (response.ok) {
        setSuccessMessage("Thanks for your contact!");
      } else {
        setErrorMessage("Please fill out all the fields.");
      }
    } catch (error) {
      setErrorMessage("An error occurred while submitting the form.");
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact</title>
        <meta
          name="description"
          content="We're always eager to hear from you! Whether you have feedback, questions, business inquiries, or just want to say hello, please don't hesitate to get in touch with us. Your thoughts and concerns are important to us, and we're committed to providing you with the best possible support."
        />
      </Helmet>
      <AlertComponent status="success" message={successMessage} />
      <AlertComponent status="error" message={errorMessage} />

      <form className="contact" onSubmit={handleSubmit}>
        <h1>Let's Connect!</h1>
        <div className="gicon-title-main">
          <span class="material-symbols-outlined">account_circle</span>
          <p>Name</p>
        </div>
        <input
          type="text"
          placeholder="Enter name"
          required
          value={name}
          onChange={(ev) => setName(ev.target.value)}
        />
        <div className="gicon-title-main">
          <span class="material-symbols-outlined">mail</span>
          <p>Email</p>
        </div>
        <input
          type="email"
          required
          placeholder="name@company.com"
          value={email}
          onChange={(ev) => setEmail(ev.target.value)}
        />
        <div className="gicon-title-main">
          <span class="material-symbols-outlined">comment</span>
          <p>Message</p>
        </div>
        <textarea
          type="text"
          value={query}
          required
          placeholder="Enter message"
          onChange={(ev) => setQuery(ev.target.value)}
        />
        <button>Send Message</button>
        <div className="contact-div">
          <h3>Business Inquiries</h3>
          <p>
            Interested in partnering with us or have a business proposal? Please
            reach us out at{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:blogsteratech@gmail.com"
            >
              blogsteratech@gmail.com
            </a>
          </p>
        </div>
      </form>
    </>
  );
}
