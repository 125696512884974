import React, { useState, useEffect } from "react";
import { Alert, AlertIcon, AlertTitle, useDisclosure } from "@chakra-ui/react";

const AlertComponent = ({ status, message, autoCloseDuration = 3000 }) => {
  const [alertMessage, setAlertMessage] = useState(message);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const bgColor = status === "success" ? "#6dcaae" : "#d83030";
  const textColor = "#ffffff";

  useEffect(() => {
    if (message) {
      setAlertMessage(message);
      onOpen();
    }
  }, [message, onOpen]);

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        onClose();
        setAlertMessage("");
      }, autoCloseDuration);

      return () => clearTimeout(timer);
    }
  }, [isOpen, autoCloseDuration, onClose]);

  if (!isOpen || !alertMessage) return null;

  return (
    <Alert
      status={status}
      variant="subtle"
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="40px"
      bg={bgColor}
      color={textColor}
      fontWeight="bold"
      fontSize="small"
      gap={"5px"}
    >
      <AlertIcon color={textColor} boxSize="20px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        {alertMessage}
      </AlertTitle>
    </Alert>
  );
};

export default AlertComponent;
