import { Helmet } from "react-helmet";
import { useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../components/userContext";
import AlertComponent from "../components/alertBox";

export default function ChangePassword() {
  const [identifier, setIdentifier] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfrimPassword] = useState("");
  const { setUserInfo } = useContext(UserContext);
  const [redirect, setRedirect] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleLogin = async (username, password) => {
    const url = `${process.env.REACT_APP_API_URL}/login`;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });

    if (response.ok) {
      const userInfo = await response.json();
      document.cookie = `token=${userInfo.token}; path=/`;
      setUserInfo(userInfo);
      setSuccessMessage("Password updated");
      setRedirect(true);
    } else {
      const errorMessage = await response.text();
      console.log(errorMessage);
      setErrorMessage("Failed to update");
    }
  };

  if (redirect) {
    return <Navigate to={"/"} />;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    try {
      const url = `${process.env.REACT_APP_API_URL}/ResetPassword`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ identifier, newPassword }),
      });

      if (!response.ok) {
        setErrorMessage("Failed to update password");
      } else {
        setSuccessMessage("Password successfully updated.");
        await handleLogin(identifier, newPassword);
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <>
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <AlertComponent status="success" message={successMessage} />
      <AlertComponent status="error" message={errorMessage} />

      <form className="login" onSubmit={handleSubmit}>
        <h1>Reset Password</h1>
        <div className="gicon-title-main">
          <span class="material-symbols-outlined">account_circle</span>
          <p>Username or Email</p>
        </div>
        <input
          type="text"
          placeholder="name@company.com"
          value={identifier}
          onChange={(e) => setIdentifier(e.target.value)}
          required
        />
        <div className="gicon-title-main">
          <span class="material-symbols-outlined">lock</span>
          <p>New Password</p>
        </div>
        <input
          type="password"
          placeholder="••••••••"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        <div className="gicon-title-main">
          <span class="material-symbols-outlined">lock</span>
          <p>Confirm Password</p>
        </div>
        <input
          type="password"
          placeholder="••••••••"
          value={confirmPassword}
          onChange={(e) => setConfrimPassword(e.target.value)}
          required
        />

        <button style={{ marginTop: "10px" }} type="submit">
          Submit
        </button>
      </form>
    </>
  );
}
